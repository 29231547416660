import { useState } from 'react'
import jwt_decode from 'jwt-decode' // import dependency
import configuration from 'src/configuration'

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token')
    if (typeof tokenString === 'undefined' || tokenString === 'undefined') {
      return null
    } else {
      const userToken = JSON.parse(tokenString)
      if (userToken !== null) {
        const { exp } = jwt_decode(userToken.token)

        const expirationTime = new Date(exp * 1000)
        if (expirationTime < new Date()) {
          // Token has expired
          // Handle expired token here
          sessionStorage.clear()
          return null
        } else {
          if (expirationTime + 120000 > new Date()) {
            // Token has about to expire, needs refresh

            return refreshToken(userToken?.token)
          }

          return userToken?.token
        }
      } else {
        sessionStorage.clear()
        return null
      }
    }
  }

  const [token, setToken] = useState(getToken())
  const restAPIUrl = `${configuration.serverURL}/refreshToken`

  const refreshToken = (userToken) => {
    sessionStorage.setItem('token', JSON.stringify(userToken))
    setToken(userToken.token)
    fetch(restAPIUrl, {
      method: 'POST',
      headers: { Authorization: `Bearer `.concat(userToken?.token) },
    })
      .then((response) => response.json())
      .then((result) => {
        setToken(result.token)
        return result.token
      })
      .catch((error) => {})
  }

  const saveToken = (userToken) => {
    sessionStorage.setItem('token', JSON.stringify(userToken))
    setToken(userToken.token)
  }

  const getStarGateToken = () => {
    const tokenString = sessionStorage.getItem('token')
    if (typeof tokenString === 'undefined' || tokenString === 'undefined') {
      return null
    } else {
      const userToken = JSON.parse(tokenString)
      if (userToken !== null) {
        const { exp } = jwt_decode(userToken.token)

        const expirationTime = new Date(exp * 1000)
        if (expirationTime < new Date()) {
          // Token has expired
          // Handle expired token here
          sessionStorage.clear()
          return null
        } else {
          if (expirationTime + 120000 > new Date()) {
            // Token has about to expire, needs refresh

            refreshToken(userToken?.token)
          }

          return userToken?.authToken
        }
      } else {
        sessionStorage.clear()
        return null
      }
    }
  }

  const [starGateToken] = useState(getStarGateToken())
  return {
    setToken: saveToken,
    token,
    starGateToken,
  }
}
export function clearSession() {
  sessionStorage.clear()
}
