import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from 'src/App'
import reportWebVitals from 'src/reportWebVitals'
import { Provider } from 'react-redux'
import store from 'src/store'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
reportWebVitals()
